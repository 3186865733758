import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { CreateEventUseCase } from "../../../domain/usecase/event/create/CreateUseCase";
import { GetAllEventsUseCase } from "../../../domain/usecase/event/getAll/GetAllUseCase";
import { State } from "../../../domain/model/ResponseState";
import { Event } from "../../../domain/model/Event";

export function HomeViewModel(
  createEventUseCase: CreateEventUseCase,
  getAllEventsUseCase: GetAllEventsUseCase
) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isBackDropLoading, setIsBackDropLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const [events, setEvents] = useState<Array<Event> | undefined>([]);

  const [newEventName, setNewEventName] = useState("");
  const [newEventAudioURL, setNewEventAudioURL] = useState("https://github.com/rafaelreis-hotmart/Audio-Sample-files/raw/master/sample.mp3");
  const [newEventTriggerDate, setNewEventTriggerDate] = useState<
    Date | undefined
  >();

  useEffect(() => {
    getAllEvents();
  }, []);

  async function createEvent() {
    if (!newEventName) {
      showSnackbar("Event name cannot be empty");
      return;
    }
    if (!newEventAudioURL) {
      showSnackbar("Event audio URL cannot be empty");
      return;
    }
    if (!newEventTriggerDate) {
      showSnackbar("Event trigger date cannot be empty");
      return;
    }

    setOpenDialog(false);
    setIsBackDropLoading(true);

    const response = await createEventUseCase.invoke({
      name: newEventName,
      audioUrl: newEventAudioURL,
      triggerDate: newEventTriggerDate.toISOString(),
    });
    switch (response.responseState) {
      case State.Success:
        showSnackbar(response.data?.message, "success");
        setNewEventName("");
        setNewEventAudioURL("");
        setNewEventTriggerDate(undefined);
        getAllEvents();
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setIsBackDropLoading(false);
  }

  async function getAllEvents() {
    setIsLoading(true);

    const response = await getAllEventsUseCase.invoke();
    switch (response.responseState) {
      case State.Success:
        setEvents(response.data);
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setIsLoading(false);
  }

  function showSnackbar(
    message: string | undefined,
    variant: "error" | "success" = "error"
  ) {
    if (message) enqueueSnackbar(message, { variant: variant });
  }

  return {
    openDialog,
    setOpenDialog,
    setNewEventName,
    setNewEventAudioURL,
    setNewEventTriggerDate,
    createEvent,
    newEventAudioURL,
    isLoading,
    isBackDropLoading,
    events,
  };
}
