import { EventRepository } from "../../../../data/repository/event/EventRepository";
import { Event } from "../../../model/Event";
import { ResponseState, State } from "../../../model/ResponseState";

export interface GetAllEventsUseCase {
  invoke: () => Promise<ResponseState<Array<Event>>>;
}

export class GetAllEventsUseCaseImpl implements GetAllEventsUseCase {
  private eventRepo: EventRepository;

  constructor(eventRepo: EventRepository) {
    this.eventRepo = eventRepo;
  }

  async invoke(): Promise<ResponseState<Array<Event>>> {
    try {
      let response = await this.eventRepo.getAll();
      if (response.isSuccessful) {
        return {
          responseState: State.Success,
          data: response.body!,
        };
      }
      return { responseState: State.Fail, error: response.errorBody! };
    } catch (error) {
      return {
        responseState: State.Error,
        error: { message: String(error), statusCode: 500 },
      };
    }
  }
}
