import NetworkApi from "../../data/network/NetworkApi";
import NetworkApiImpl from "../../data/network/NetworkApiImpl";
import { AuthRepository } from "../../data/repository/auth/AuthRepository";
import { AuthRepositoryImpl } from "../../data/repository/auth/AuthRepositoryImpl";
import { EventRepository } from "../../data/repository/event/EventRepository";
import { EventRepositoryImpl } from "../../data/repository/event/EventRepositoryImpl";
import {
  LoginUseCase,
  LoginUseCaseImpl,
} from "../../domain/usecase/auth/Login/LoginUseCase";
import {
  CreateEventUseCase,
  CreateEventUseCaseImpl,
} from "../../domain/usecase/event/create/CreateUseCase";
import {
  GetAllEventsUseCase,
  GetAllEventsUseCaseImpl,
} from "../../domain/usecase/event/getAll/GetAllUseCase";
import { provideApiBuilder } from "./NetworkModule";

function provideNetworkApi(): NetworkApi {
  return new NetworkApiImpl(provideApiBuilder());
}

// auth
function provideAuthRepository(): AuthRepository {
  return new AuthRepositoryImpl(provideNetworkApi());
}
export function provideLoginUseCase(): LoginUseCase {
  return new LoginUseCaseImpl(provideAuthRepository());
}

// event
function provideEventRepository(): EventRepository {
  return new EventRepositoryImpl(provideNetworkApi());
}
export function provideCreateEventUseCase(): CreateEventUseCase {
  return new CreateEventUseCaseImpl(provideEventRepository());
}
export function provideGetAllEventsUseCase(): GetAllEventsUseCase {
  return new GetAllEventsUseCaseImpl(provideEventRepository());
}
