import { AccessToken, Auth, Message } from "../../domain/model/Auth";
import { Event } from "../../domain/model/Event";
import { ApiService } from "../api/ApiService";
import { Response } from "../api/Response";
import { API_ENDPOINTS } from "../constant/ApiConstants";
import NetworkApi from "./NetworkApi";

export default class NetworkApiImpl implements NetworkApi {
  apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }

  login(auth: Auth): Promise<Response<AccessToken>> {
    return this.apiService.post<AccessToken>(API_ENDPOINTS.LOGIN, auth);
  }

  // event
  createEvent(event: Event): Promise<Response<Message>> {
    return this.apiService.post<Message>(API_ENDPOINTS.EVENTS, event);
  }
  getEvents(): Promise<Response<Array<Event>>> {
    return this.apiService.get<Array<Event>>(API_ENDPOINTS.EVENTS);
  }
}
