import {
  AppBar,
  Typography,
  Toolbar,
  IconButton,
  Popover,
  Stack,
} from "@mui/material";
import SharedPreferences from "../../core/utils/SharedPreferences";
import { Logout } from "@mui/icons-material";
import { COLOR_MAIN, COLOR_WHITE } from "../../data/colors/Colors";
import { CustomTextView } from "./CustomTextView";
import { STRINGS_FIRTEK_AUDIO_SYNC } from "../../data/strings/Strings";

export function AppToolbar() {
  return (
    <AppBar position="static">
      <Toolbar sx={{ background: COLOR_MAIN }}>
        <Stack direction={"row"} width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
          
        <CustomTextView
          text={STRINGS_FIRTEK_AUDIO_SYNC}
          variant="h5"
          fontWeight="bold"
          textColor={COLOR_WHITE}
        />
        <IconButton
          color="inherit"
          size="large"
          onClick={() => {
            SharedPreferences.clear();
          }}
        >
          <Logout />
        </IconButton>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
