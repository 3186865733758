import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  Grid,
  Icon,
  IconButton,
  Stack,
  Toolbar,
} from "@mui/material";
import { HomeViewModel } from "./HomeViewModel";
import {
  COLOR_BLACK,
  COLOR_LESS_BLACK,
  COLOR_LIGHT_GRAY,
  COLOR_MAIN,
  COLOR_WHITE,
} from "../../../data/colors/Colors";
import { AppToolbar } from "../../component/Toolbar";
import { CustomTextView } from "../../component/CustomTextView";
import {
  STRINGS_AUDIO_URL,
  STRINGS_EVENTS,
  STRINGS_NAME,
} from "../../../data/strings/Strings";
import {
  Add,
  AddBoxRounded,
  AddRounded,
  Check,
  CheckBoxRounded,
  Close,
  CloseRounded,
} from "@mui/icons-material";
import { CustomTextField } from "../../component/CustomTextField";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import {
  DesktopDateTimePicker,
  StaticDateTimePicker,
} from "@mui/x-date-pickers";
import { MAIN_FONT_FAMILY } from "../../../data/constant/Constants";
import AutoHeight from "../../component/AutoHeightView";
import dayjs from "dayjs";
import {
  provideCreateEventUseCase,
  provideGetAllEventsUseCase,
} from "../../di/UseCasesModule";
import { LoadingView } from "../../component/LoadingView";
import { EmptyView } from "../../component/EmptyView";
import { CustomCardView } from "../../component/CustomCardView";
import { formatDate } from "../../../core/utils/Utils";

export default function HomeView() {
  const viewModel = HomeViewModel(
    provideCreateEventUseCase(),
    provideGetAllEventsUseCase()
  );
  return (
    <Box>
      <Stack height={"100vh"} width={"100vw"}>
        <AppToolbar />
        <Container maxWidth={false}>
          <Grid container spacing={2} p={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Stack
                width={"100%"}
                alignItems={"center"}
                justifyContent={"space-between"}
                direction={"row"}
              >
                <CustomTextView
                  text={STRINGS_EVENTS}
                  variant="h4"
                  fontWeight="bold"
                  textColor={COLOR_LESS_BLACK}
                />
                <IconButton onClick={() => viewModel.setOpenDialog(true)}>
                  <AddRounded
                    sx={{ color: COLOR_LESS_BLACK, width: 40, height: 40 }}
                  />
                </IconButton>
              </Stack>
            </Grid>
            <>
              {viewModel.isLoading ? (
                <LoadingView />
              ) : (
                <>
                  {viewModel.events && (
                    <>
                      {viewModel.events.length === 0 ? (
                        <EmptyView />
                      ) : (
                        <Grid container spacing={2} pt={1} pb={3}>
                          {viewModel.events.map((event) => {
                            return (
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                                key={event._id}
                              >
                                <CustomCardView
                                  children={
                                    <Stack spacing={2}>
                                      <CustomTextView
                                        text={event.name}
                                        textColor={COLOR_BLACK}
                                        fontWeight="bold"
                                        variant="h5"
                                      />
                                      <CustomTextView
                                        text={event.audioUrl}
                                        textColor={COLOR_BLACK}
                                      />
                                      <CustomTextView
                                        text={formatDate(
                                          new Date(event.triggerDate ?? "")
                                        )}
                                        textColor={COLOR_BLACK}
                                      />
                                    </Stack>
                                  }
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          </Grid>
        </Container>
      </Stack>

      <Backdrop
        sx={{
          color: COLOR_LESS_BLACK,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={viewModel.isBackDropLoading}
      >
        <CircularProgress sx={{ color: COLOR_WHITE }} />
      </Backdrop>

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={viewModel.openDialog}
        onClose={() => viewModel.setOpenDialog(false)}
        PaperProps={{
          sx: {
            borderRadius: 6,
            p: 4,
            minHeight: "30vh",
          },
        }}
      >
        <Stack spacing={2}>
          <Stack
            direction={"row"}
            width={"100%"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <CustomTextView
              text="New Event"
              variant="h5"
              textColor={COLOR_BLACK}
              fontWeight="bold"
            />
            <Stack spacing={1} alignItems={"center"} direction={"row"}>
              <IconButton onClick={() => viewModel.setOpenDialog(false)}>
                <CloseRounded
                  sx={{ color: COLOR_LESS_BLACK }}
                  fontSize="large"
                />
              </IconButton>
              <IconButton onClick={() => viewModel.createEvent()}>
                <CheckBoxRounded sx={{ color: "green" }} fontSize="large" />
              </IconButton>
            </Stack>
          </Stack>
          <CustomTextField
            onTextChange={(txt) => {
              viewModel.setNewEventName(txt);
            }}
            type="text"
            placeholder={STRINGS_NAME}
            textColor={COLOR_BLACK}
            onHoverBorderColor={COLOR_LIGHT_GRAY}
            onFocusBorderColor={COLOR_LIGHT_GRAY}
            borderColor={"blue"}
            backgroundColor={"transparent"}
          />
          <CustomTextField
            onTextChange={(txt) => {
              viewModel.setNewEventAudioURL(txt);
            }}
            type="url"
            value={viewModel.newEventAudioURL}
            placeholder={STRINGS_AUDIO_URL}
            textColor={COLOR_BLACK}
            onHoverBorderColor={COLOR_LIGHT_GRAY}
            onFocusBorderColor={COLOR_LIGHT_GRAY}
            borderColor={"blue"}
            backgroundColor={"transparent"}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateTimePicker"]}>
              <StaticDateTimePicker
                disablePast
                defaultValue={dayjs(new Date().toISOString())}
                onChange={(date) => {
                  viewModel.setNewEventTriggerDate(date?.toDate());
                }}
                slotProps={{
                  layout: { sx: { fontFamily: MAIN_FONT_FAMILY } },
                  actionBar: { actions: ["today"] },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Stack>
      </Dialog>
    </Box>
  );
}
