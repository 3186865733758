// login
export const STRINGS_LOGIN = "Login"
export const STRINGS_EMAIL = "Email"
export const STRINGS_PASSWORD = "Password"
export const STRINGS_FIRTEK_AUDIO_SYNC = "Firetek Audio Sync"

// home
export const STRINGS_EVENTS = "Events"
export const STRINGS_NAME = "Name" 
export const STRINGS_AUDIO_URL = "Audio URL" 
export const STRINGS_NOTHING_TO_SHOW = "Nothing to show";

// errors
export const STRINGS_404_NOT_FOUND = "404 Not Found";
export const STRINGS_EMAIL_NOT_VALID = "Email not valid";
export const STRINGS_EMAIL_REQUIRED = "Email is required";
export const STRINGS_PASSWORD_REQUIRED = "Password is required";