import { Box, Typography } from "@mui/material";
import { MAIN_FONT_FAMILY } from "../../data/constant/Constants";
import {
  STRINGS_404_NOT_FOUND,
  STRINGS_NOTHING_TO_SHOW,
} from "../../data/strings/Strings";
import {
  Error,
  ErrorTwoTone,
  HourglassEmpty,
  HourglassEmptyRounded,
} from "@mui/icons-material";
import { COLOR_LESS_BLACK } from "../../data/colors/Colors";

interface p {
  minHeight?: string | number;
}
export function EmptyView(p: p) {
  return (
    <Box
      sx={{
        minHeight: p.minHeight ?? "70vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <HourglassEmptyRounded
        fontSize="large"
        sx={{ color: COLOR_LESS_BLACK }}
      />
      <Typography
        fontFamily={MAIN_FONT_FAMILY}
        fontWeight={"bold"}
        variant="h5"
      >
        {STRINGS_NOTHING_TO_SHOW}
      </Typography>
    </Box>
  );
}
